import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["toClone", "choicesFields", "editFormContainer", "resultsList"];

  connect() {
    
  }

  addChoiceField(e) {
    e.preventDefault();
    let newField = this.toCloneTarget.cloneNode(true);
    newField.removeAttribute("data-survey-target");
    newField.classList.remove("hidden");
    this.choicesFieldsTarget.appendChild(newField);
  }

  deleteChoiceField(e) {
    e.preventDefault();
    e.target.closest(".parent-field").remove();
  }

  showEditForm(e) {
    e.preventDefault();
    this.editFormContainerTarget.classList.toggle("hidden");
    this.resultsListTarget.classList.toggle("hidden");
  }

  copyCode(e) {
    e.preventDefault();
    let text = e.target.textContent;
    this.copyRichText(text);
  }

  
  copyRichText(text) {
    const listener = function(ev) {
      ev.preventDefault();
      ev.clipboardData.setData('text/plain', text);
      ev.clipboardData.setData('text/html', text);
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }



}