import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["textarea", "submitBtn", "spinner", "surveyArea", "toggleSurveyBtn", "notesField"];

  connect() {
    document.addEventListener("turbo:before-stream-render", (e) => {
      var appended = e.target
      if (appended.target == `survey_found_acd_by`) {

        setTimeout(() => {
          this.element.remove();
        }, 3500);

      }
    })

  }

  submitSurvey(e) {
    // this.submitBtnTarget.disabled = true;
    this.submitBtnTarget.classList.add("hidden");
    this.spinnerTarget.classList.remove("hidden");
  }

  toggleTextarea(e) {
    if (["social", "altro"].includes(e.target.value)) {
      this.textareaTarget.required = true;
      this.textareaTarget.classList.remove("hidden");
      if (e.target.value == "social") {
        this.textareaTarget.placeholder = "Quale social?"
      } else {
        this.textareaTarget.placeholder = "Inserisci più dettagli"
      }
    } else {
      this.textareaTarget.required = false;
      this.textareaTarget.classList.add("hidden");
      this.textareaTarget.placeholder = "Inserisci più dettagli"
    }
  }

  toggleSurvey(e) {
    this.surveyAreaTarget.classList.toggle("hidden");
    this.toggleSurveyBtnTarget.classList.toggle("hidden");
  }

  showNotesField(e) {
    if (this.hasNotesFieldTarget) {
      let val = e.target.value;
      console.log(val);
      if (val == "Altro") {
        this.notesFieldTarget.classList.toggle("hidden");
      }
    }
  }

}